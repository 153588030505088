.displayErrors.valid-feedback{
    font-size: 10px;
    color:#ff1d1d;
    display: block;
}

.noError{
    display: none;
}

#weekly-tasks .box-form .formLogin{
    margin: 0 auto;
    width: 30%;
}

#weekly-tasks .box-form label{
    display: flex;
}
