.e-kanban-header .header-template-wrap {
  display: inline-flex;
  font-size: 18px;
  font-weight: 400;
}

.e-kanban-header .header-icon {
  font-family: "Kanban priority icons";
  margin-top: 3px;
  width: 10%;
}

.e-kanban-header .header-template-wrap .header-text {
  margin-left: 15px;
}

.e-kanban-header .TODO:before {
  content: "\e60b";
  color: #03d2d6;
  font-size: 22px;
}

.e-kanban-header .TODO_CLIENT:before {
  content: "\e60b";
  color: goldenrod;
  font-size: 22px;
}

.e-kanban-header .IN_PROGRESS:before {
  content: "\ec2b";
  color: palevioletred;
  font-size: 22px;
}

.e-kanban-header .AT_CLIENT:before {
  content: "\e816";
  color: orangered;
  font-size: 22px;
}

.e-kanban-header .REVIEW:before {
  content: "\e345";
  color: #1b314c;
  font-size: 22px;
}

.e-kanban-header .DONE:before {
  content: "\e21d";
  color: green;
  font-size: 22px;
}

.e-kanban
  .e-kanban-table.e-content-table
  .e-content-row:not(.e-swimlane-row)
  td {
  background-color: #1b314c;
}

.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-show-add-button {
  border: 2px dashed #03d2d6;
  border-radius: 10px;
}

.e-kanban
  .e-kanban-table.e-content-table
  .e-content-row
  .e-show-add-button
  .e-show-add-icon {
  color: #03d2d6;
}

.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card {
  background-color: white;
  border-radius: 10px;
  color: #1b314c !important;
}

.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card
  .card-content {
  padding: 10px;
  min-height: 50px;
}

.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card
  .card-content.highlighted-card {
  color: whitesmoke !important;
  background-color: orangered !important;
  border-radius: 10px;
}

.e-dialog .e-dlg-header {
  width: 100%;
}

.spinner {
  background-color: white;
  opacity: 0.5;
}
