.e-kanban-header .header-template-wrap {
    display: inline-flex;
    font-size: 18px;
    font-weight: 400;
  }

  .e-kanban-header .header-icon {
    font-family: "Kanban priority icons";
    margin-top: 3px;
    width: 10%;
  }

  .e-kanban-header .header-template-wrap .header-text {
    margin-left: 15px;
  }

  .e-kanban
    .e-kanban-table.e-content-table
    .e-content-row:not(.e-swimlane-row)
    td {
    background-color: #1b314c;
  }

  .e-kanban .e-kanban-content .e-content-row .e-content-cells .e-show-add-button {
    border: 2px dashed #03d2d6;
    border-radius: 10px;
  }

  .e-kanban
    .e-kanban-table.e-content-table
    .e-content-row
    .e-show-add-button
    .e-show-add-icon {
    color: #03d2d6;
  }

  .e-kanban
    .e-kanban-content
    .e-content-row
    .e-content-cells
    .e-card-wrapper
    .e-card {
    background-color: white;
    border-radius: 10px;
    color: #1b314c !important;
  }

  .e-kanban
    .e-kanban-content
    .e-content-row
    .e-content-cells
    .e-card-wrapper
    .e-card
    .card-content {
    padding: 10px;
    min-height: 50px;
  }

  .e-kanban
    .e-kanban-content
    .e-content-row
    .e-content-cells
    .e-card-wrapper
    .e-card
    .card-content.highlighted-card {
    color: whitesmoke !important;
    background-color: orangered !important;
    border-radius: 10px;
  }

  .e-dialog .e-dlg-header {
    width: 100%;
  }

  .spinner {
    background-color: white;
    opacity: 0.5;
  }
