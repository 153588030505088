.navbar {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 15px;
}

.navbar .navbar-nav .nav-link {
  color: white !important;
}

.navbar .navbar-nav .nav-link.text-dark {
  color: #1b314c !important;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link:focus {
  color: #03d2d6 !important;
}

.navbar .navbar-nav .dropdown-item:hover .nav-link.text-dark {
  color: white !important;
}

.nav-link {
  padding: 0;
}

.navbar .navbar-brand {
  border: 1px solid #03d2d6;
  border-left: 1px solid white;
  border-top: 1px solid white;
  border-radius: 5px;
  padding: 5px;
}

.navbar .logos {
  margin-right: 20px;
  margin-left: auto;
  background-color: white;
  border: 1px solid white;
  border-radius: 5px;
  padding: 5px;
}

.logos img {
  max-height: 35px;
}

.mr-auto .e-input-group {
  margin-top: -4px;
  background-color: #1b314c;
  color: white;
}

.mr-auto .e-input-group-icon {
  color: white !important;
}

.flag-language-select img {
  max-height: 30px;
}
