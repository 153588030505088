.displayErrors.valid-feedback {
  font-size: 10px;
  color: #ff1d1d;
  display: block;
}

.noError {
  display: none;
}

#login .box-form .formLogin {
  margin: 0 auto;
  width: 30%;
}

#login .box-form label {
  display: flex;
}

.link-btn {
  border-color: transparent !important;
}

.link-btn:hover {
  border-color: transparent !important;
  background-color: white !important;
  color: #1b314c !important;
}

.link-btn:active {
  box-shadow: none !important;
  border-color: transparent !important;
}
