@import "../node_modules/@syncfusion/ej2-base/styles/bootstrap.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/bootstrap.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/bootstrap.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/bootstrap.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/bootstrap.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/bootstrap.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/bootstrap.css";
@import "../node_modules/@syncfusion/ej2-layouts/styles/bootstrap.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap.css";
@import "../node_modules/@syncfusion/ej2-react-grids/styles/bootstrap.css";
@import "../node_modules/@syncfusion/ej2-react-kanban/styles/bootstrap.css";
@import "../node_modules/@syncfusion/ej2-react-richtexteditor/styles/bootstrap.css";
@import "../node_modules/@syncfusion/ej2-react-filemanager/styles/bootstrap.css";

body {
  width: 100%;
  height: 100vh;
  color: #1b314c !important;
}

/* fix since icons won't work otherwise */
.e-icons {
  font-family: "e-icons" !important;
}

.bg-primary {
  background-color: #1b314c !important;
}

.btn,
.e-btn {
  color: #1b314c !important;
  background-color: white !important;
  border-color: #1b314c !important;
}

.e-dlg-closeicon-btn {
  border-color: transparent !important;
}

.btn-primary,
.e-btn.e-primary {
  color: white !important;
  background-color: #1b314c !important;
  border-color: #1b314c !important;
}

.btn-danger,
.e-btn.e-danger {
  color: whitesmoke !important;
  background-color: orangered !important;
  border-color: orangered !important;
}

.btn:hover,
.e-btn:hover {
  color: white !important;
  background-color: #03d2d6 !important;
  border-color: #03d2d6 !important;
}

.e-dlg-closeicon-btn:hover {
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn:disabled,
.e-btn:disabled {
  opacity: 0.5 !important;
}

.e-toolbar,
.e-toolbar .e-toolbar-items {
  background-color: #1b314c !important;
}

.e-toolbar-item .e-tbar-btn:hover .e-btn-icon,
.e-toolbar-item .e-tbar-btn:hover .e-tbar-btn-text {
  color: white !important;
}

.e-toolbar-item.e-active .e-tbar-btn {
  border: 2px solid #03d2d6 !important;
}

.form-control:focus,
.e-input-group.e-input-focus {
  border-color: #03d2d6 !important;
}

.e-pager .e-currentitem {
  background-color: #1b314c !important;
}

.e-dropdownbase .e-list-item.e-active {
  color: white !important;
  background-color: #1b314c !important;
}

.dropdown-item:hover,
.e-dropdownbase .e-list-item.e-hover {
  color: white !important;
  background-color: #03d2d6 !important;
}
